<template>
  <svg
    width="21"
    height="17"
    viewBox="0 0 21 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_39859_118068)">
      <path
        d="M16.5 1H12.5V5H16.5V1Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M8.5 11H4.5V15H8.5V11Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M16.5 3H19.5"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        d="M12.5 3H1.5"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        d="M4.5 13H1.5"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        d="M8.5 13H19.5"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
    </g>
    <defs>
      <clipPath id="clip0_39859_118068">
        <rect width="20" height="17" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
</template>
